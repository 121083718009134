import request from '@/utils/request'


export function getUserlist(query) {
    return request({
        url: '/wechat/merWxMsg/userlist',
        method: 'get',
        params: query
    })
}

export function getUserHostorylist(query) {
    return request({
        url: '/vtax-wechat/out/imList',
        method: 'get',
        params: query
    })
}

export function getUserProfile() {
    return request({
        url: '/system/user/profile',
        method: 'get'
    })
}

export function wxSsendMsg(data) {
    return request({
        url: '/wechat/out/sendIM',
        method: 'post',
        data: data
    })
}
export function wxGetAllUnread(query) {

    return request({
        url: '/wechat/merWxMsg/getAllUnread',
        method: 'get',
        params: query
    })
}


//获取分配员工
export function getSysStaffTask(query) {
    return request({
        url: '/system/sysStaff/list',
        method: 'get',
        params: query
    })
}