<template>
  <div id="app">
    <HelloWorld
      ref="lemmonImin"
      @func="clickContatc"
      msg="Welcome to Your Vue.js App"
    />
  </div>
</template>

<script>
import HelloWorld from "./components/HelloWorld.vue";
import { wxGetAllUnread } from "@/api/chat/chat";
import { randomWord } from "@/utils/vtax";
import { getToken, setToken, setCoustomer } from "@/utils/auth";
import { sendWebsocket, closeWebsocket } from "@/utils/socket.js";
export default {
  name: "App",
  components: {
    HelloWorld,
  },
  data() {
    return {
      show2: false,
      isConnent: false,
      allUnread: 0,
      user: {},
      userName: "",
    };
  },
  created() {
    // console.log(this.isConnent, "12312312");

    if (getToken()) {
      this.userName = getToken();
    } else {
      //网页版
      this.userName = randomWord(true, 3, 7);
      setToken(this.userName);
      //企业微信版本
      // this.getCorpId();
    }

    if (this.isConnent == false) {
      this.getUser();
    } else {
    }
  },
  methods: {
    getUser() {
      this.requstWs();
    },
    wxAllUnread() {
      wxGetAllUnread().then((res) => {
        this.allUnread = res.data.unreadNum;
        console.log(this.avatar, this.name, res);
      });
    },
    satrtImin() {
      // console.log(document.domain);
      if (this.isConnent == false) {
        this.$message.error("ws连接异常，请刷新重试！");
      } else {
        this.$refs.lemmonImin.showImin(
          "https://api.vtaxcloud.com" + this.avatar
        );
      }
    },
    wsMessage(data) {
      const dataJson = data;
      this.isConnent = true;
      // console.log(dataJson, "12312312321");
      console.log(dataJson, "收到数据");

      if (dataJson.msgId) {
        //   this.allUnread++;
        this.$refs.lemmonImin.requestData(
          dataJson,
          "https://api.vtaxcloud.com" + this.avatar
        );
      }

      // 这里写拿到数据后的业务代码
    },
    // ws连接失败，组件要执行的代码
    wsError() {
      // 比如取消页面的loading
      // Message.error("ws连接异常，请稍候重试");
      this.isConnent = false;
      console.log("链接失败");
    },

    requstWs() {
      // 防止用户多次连续点击发起请求，所以要先关闭上次的ws请求。
      // closeWebsocket();
      // 跟后端协商，需要什么参数数据给后台

      const obj = {
        message: "开始连接",
        userName: this.userName,
        to: "AI00002",
      };

      // 发起ws请求 //api.vtax.ahjstong.cn http://192.168.1.100:8080
      sendWebsocket(
        "wss://api.vtaxcloud.com" + "/vtax-wechat/webSocket/" + this.userName,
        obj,
        this.wsMessage,
        this.wsError
      );
    },

    clickContatc(contact) {
      // console.log(contact, "sadadadsadsd");

      this.allUnread =
        this.allUnread - contact.unread < 0
          ? 0
          : this.allUnread - contact.unread;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
