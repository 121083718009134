import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [

  {
    path: '',
    component: () => import("../views/index"),
    hidden: true
  },
  {
    path: '/',
    component: () => import("../views/index"),
    hidden: true
  },

  {
    path: '/moeny',
    component: () => import("../views/money/index"),
    hidden: true
  },

]

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})
