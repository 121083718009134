import Vue from 'vue'
import App from './App.vue'
// import Money from './Money.vue'
import Element from 'element-ui'
import LemonIMUI from 'lemon-imui'
import 'lemon-imui/dist/index.css'
import 'element-ui/lib/theme-chalk/index.css';
// import Cookies from 'js-cookie'
// import Viewer from 'v-viewer'
// import 'viewerjs/dist/viewer.css'

// import VueRouter from 'vue-router'
// import routers from './router/routers'
import router from './router'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

// import Vant from 'vant';
// import 'vant/lib/index.css';

Vue.use(LemonIMUI);
// Vue.use(Vant);
Vue.config.productionTip = false
Vue.use(Element)
Vue.use(Viewer)
Viewer.setDefaults({
  Options: { 'inline': true, 'button': true, 'navbar': true, 'title': true, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
