<template>
  <div id="lemon">
    <div style="text-align: left">
      <lemon-imui
        width="380px"
        simple
        :user="user"
        :contextmenu="contextmenu"
        ref="IMUI"
        @pull-messages="handlePullMessages"
        @message-click="handleMessageClick"
        @send="handleSend"
      >
      </lemon-imui>
    </div>

    <viewer
      :images="images"
      @inited="inited"
      class="viewer"
      ref="viewer"
      v-show="showPeview"
    >
      <img v-for="src in this.images" :src="src" :key="src" />
    </viewer>
    <!-- <div v-show="!show2" class="start_btn">
      <el-badge :value="100" :max="10" class="item">
        <el-button
          @click="show2 = !show2"
          type="info"
          icon="el-icon-message"
          circle
        ></el-button>
      </el-badge>
    </div> -->
  </div>
</template>

<script>
import {
  getUserlist,
  getUserHostorylist,
  wxSsendMsg,
  getSysStaffTask,
} from "@/api/chat/chat.js";
// import { uuidGenerator } from "@/utils/uuid";
import { getToken, setToken } from "@/utils/auth";
import { getQnToken, getNoLoginQnToken } from "@/api/upload/upload";
var qiniu = require("qiniu-js");
const getTime = () => new Date().getTime();
const generateRandId = () => Math.random().toString(36).substr(-8);

export default {
  name: "lemon",
  components: {},
  props: {},
  data() {
    return {
      // 七牛云上传储存区域的上传域名（华东、华北、华南、北美、东南亚）
      upload_qiniu_url: "https://up-z2.qiniup.com/",
      // 七牛云返回储存图片的子域名
      upload_qiniu_addr: "",
      qiniuData: {
        key: "",
        token: "",
        config: {
          useCdnDomain: true,
        },
      },
      contextmenu: [
        // {
        //   click: (e, instance, hide) => {
        //     const { IMUI, message } = instance;
        //     const data = {
        //       id: generateRandId(),
        //       type: "event",
        //       //使用 jsx 时 click必须使用箭头函数（使上下文停留在vue内）
        //       content: (
        //         <div>
        //           <span>
        //             你撤回了一条消息{" "}
        //             <span
        //               v-show={message.type == "text"}
        //               style="color:#333;cursor:pointer"
        //               content={message.content}
        //               on-click={(e) => {
        //                 IMUI.setEditorValue(e.target.getAttribute("content"));
        //               }}
        //             >
        //               重新编辑
        //             </span>
        //           </span>
        //         </div>
        //       ),

        //       toContactId: message.toContactId,
        //       sendTime: getTime(),
        //     };
        //     IMUI.removeMessage(message.id);
        //     IMUI.appendMessage(data, true);
        //     hide();
        //   },
        //   visible: (instance) => {
        //     return instance.message.fromUser.id == this.user.id;
        //   },
        //   text: "撤回消息",
        // },
        // {
        //   visible: (instance) => {
        //     return instance.message.fromUser.id != this.user.id;
        //   },
        //   text: "举报",
        // },
        // {
        //   text: "转发",
        // },
        {
          visible: (instance) => {
            return instance.message.type == "text";
          },
          text: "复制文字",
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            this.copyJSON(message.content);
            hide();
            // console.log(message);
          },
        },
        {
          visible: (instance) => {
            return instance.message.type == "image";
          },
          text: "下载图片",
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            this.downLoadPic(message);
            hide();
            // console.log(message);
          },
        },
        {
          visible: (instance) => {
            //  const { IMUI, message } = instance;
            // this.downLoadFile(message);
            // hide();
            return instance.message.type == "file";
          },
          click: (e, instance, hide) => {
            const { IMUI, message } = instance;
            this.downLoadFile(message);
            hide();
          },
          text: "下载文件",
        },
        // {
        //   click: (e, instance, hide) => {
        //     const { IMUI, message } = instance;
        //     IMUI.removeMessage(message.id);
        //     hide();
        //   },
        //   icon: "lemon-icon-folder",
        //   color: "red",
        //   text: "删除",
        // },
      ],
      pageNum: 1,
      corpid: "",
      agentid: "",
      sendKey: function (e) {
        console.log("sadsa", e);
        if (e.keyCode == 13) {
          e.preventDefault();
        }

        return e.keyCode == 13;
      },
      //是否隐藏最左边菜单栏
      hideMenu: false,
      hideMenuAvatar: false,
      //隐藏姓名
      hideMessageName: false,
      show2: false,
      showPeview: false,
      images: [""],
      sendUser: "",
      isShow: true,

      user: {
        id: "contact-3",
        displayName: "我",
        avatar: "",
      },
    };
  },
  mounted() {
    const { IMUI } = this.$refs;
    IMUI.initContacts([
      {
        id: "w00002",
        displayName: "在线客服",
        avatar: "",
        type: "single",
        index: "Z",
      },
    ]);
    setTimeout(() => {
      IMUI.changeContact("w00002");
    }, 2000);
  },
  created() {
    this.getUploadInfo();
    this.sendUser = getToken();
    // this.sendUser = "w12345";
  },
  beforeDestroy() {},
  methods: {
    inited(viewer) {
      this.$viewer = viewer;
    },
    getUploadInfo() {
      getQnToken().then((res) => {
        console.log(res, "getQnTokengetQnTokengetQnTokengetQnToken");
        this.upload_qiniu_addr = "http://" + res.data.domain + "/";
        console.log(this.upload_qiniu_addr);
        this.qiniuData.token = res.data.token;
        this.qiniuData.key = res.data.path;
        // this.qiniuData.key = res.data.region;
      });
    },
    //获取联系人
    handleGetUserList(sendUser) {
      // const { IMUI } = this.$refs;

      getUserlist().then((res) => {
        let list = this.buildUserList(res.rows);
        getSysStaffTask({ isWork: 1, isWorking: 1 }).then((res) => {
          this.buuld(res.rows, list);
        });
      });
    },
    //点击聊天窗口中的消息时会触发该事件
    handleMessageClick(e, key, message) {
      console.log(message, "clickMessage");

      if (message.type == "image") {
        this.images = [message.content];
        console.log(this.images);
        this.$viewer.show();
        return;
      }

      if (message.toContactId === "admin") {
        this.$message.error("系统管理员不接受消息！");
        // message.status = "failed";

        return;
      }

      const { IMUI } = this.$refs;
      if (key == "status" && message.status === "failed") {
        IMUI.updateMessage({
          id: message.id,
          toContactId: message.toContactId,
          status: "going",
          content: "重新发送消息...",
        });
        setTimeout(() => {
          IMUI.updateMessage({
            id: message.id,
            toContactId: message.toContactId,
            status: "failed",
            content: "还是发送失败",
          });
        }, 2000);
      }
    },
    //当发送新消息时会触发该事件
    handleSend(message, next, file) {
      console.log(message);
      if (message.toContactId === "admin") {
        this.$message.error("系统管理员不接受消息！");
        message.status = "failed";
        // const { IMUI } = this.$refs;
        // IMUI.removeMessage(message.id);

        return;
      }
      if (message.type == "text") {
        this.sendMessageforConent(message, next);
      }
      if (message.type == "image" || message.type == "file") {
        this.zh_uploadFile_change(file, message, next);
      }
    },

    sendMessageforConent(message, next) {
      console.log(message, "message111");
      wxSsendMsg({
        corpid: this.corpid,
        agentid: this.agentid,
        merId: "4",
        sendUser: this.sendUser,
        // sendUser: message.fromUser.toContactId,
        toUser: this.sendUser,
        msgType: message.type,
        remark: message.fileSize,
        content: message.type == "text" ? message.content : message.fileName,
        picUrl:
          message.type == "image" || message.type == "file"
            ? message.content
            : "",
      }).then((res) => {
        if (res.code == 200) {
          next();
        } else {
          next({
            status: "failed",
          });
        }
      });
    },

    appendEventMessage() {
      const { IMUI } = this.$refs;
      const message = {
        id: generateRandId(),
        type: "event",
        content: (
          <span>
            邀请你加入群聊{" "}
            <span
              style="color:#333;cursor:pointer"
              on-click={() => alert("OK")}
            >
              接受
            </span>
          </span>
        ),
        toContactId: "contact-3",
        sendTime: getTime(),
      };
      IMUI.appendMessage(message, true);
    },

    //修改联系人
    handleChangeContact(contact, instance) {
      console.log("Event:change-contact", contact, instance);

      this.$emit("func", contact);
      this.pageNum = 1;
      this.agentid = contact.agentid;
      this.corpid = contact.corpid;
      instance.updateContact({
        id: contact.id,
        unread: 0,
      });
      instance.closeDrawer();
    },

    showImin(avatar) {
      // console.log(avatar, "avataravataravataravatar");
      this.user.avatar = avatar;
      this.show2 = !this.show2;
      this.handleGetUserList();
      this.getUploadInfo();
    },

    requestData(data, avatar) {
      const { IMUI } = this.$refs;
      this.user.avatar = avatar;

      const msgData = {
        id: data.msgId,
        status: "succeed",
        type: data.msgType,
        sendTime: data.createTime,
        fileName: data.content,
        fileSize: data.remark,
        content:
          data.msgType == "image" || data.msgType == "file"
            ? data.picUrl
            : data.content,
        toContactId: data.sendUser,
        fromUser: {
          id: data.sendUser,
          displayName: "在线客服",
          avatar: data.sendUserAvatar,
        },
      };
      this.$nextTick(() => {
        IMUI.appendMessage(msgData, true);
      });

      getUserHostorylist({
        sendUser: this.sendUser,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        let contact = { unread: 1 };
        this.$emit("func", contact);
      });
    },
    //复制文本
    async copyJSON(text) {
      let target = document.createElement("input"); //创建input节点
      target.value = text; // 给input的value赋值
      document.body.appendChild(target); // 向页面插入input节点
      target.select(); // 选中input
      try {
        await document.execCommand("Copy"); // 执行浏览器复制命令
        console.log("复制成功");
      } catch {
        console.log("复制失败");
      }
    },
    //下载文件
    downLoadFile(message) {
      console.log("file", message);
      window.open(message.content, "_blank");
    },

    //下载图片
    downLoadPic(row) {
      console.log(row);

      this.downloadIamge(row.content, row.sendTime);
    },
    downloadIamge(imgsrc, name) {
      //下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); //得到图片的base64编码数据

        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },
    //设置联系人
    buuld(data, list) {
      const userList = [];
      console.log(data, list);
      const { IMUI } = this.$refs;
      for (let index = 0; index < data.length; index++) {
        if (!this.remove(data[index], list)) {
          userList.push({
            id: data[index].username,
            displayName: data[index].staffName,
            avatar: "",
            index: "全部",
            // corpid: data[index].corpid,
            // unread: data[index].unreadNum,
            agentid: "",
            // lastSendTime: new Date(data[index].createTime),
            // lastContent: data[index].content ? data[index].content : "新消息",
          });
        }
      }
      for (let index = 0; index < list.length; index++) {
        userList.push(list[index]);
      }
      // let allList = list.contact(userList);
      console.log(userList, "userList");
      IMUI.initContacts(userList);
    },
    remove(item, list) {
      for (let j = 0; j < list.length; j++) {
        if (item.username === list[j].id) {
          return true;
        }
      }
      return false;
    },

    buildUserList(data) {
      const { IMUI } = this.$refs;
      IMUI.changeContact();
      this.user = {
        id: data[0].toUser,
        displayName: data[0].toUser,
      };
      const userList = [];
      for (let index = 0; index < data.length; index++) {
        userList[index] = {
          id: data[index].sendUser,
          displayName: data[index].sendUserName,
          avatar: data[index].sendUserAvatar,
          index: "历史消息",
          corpid: data[index].corpid,
          unread: data[index].unreadNum,

          agentid: data[index].agentid,
          lastSendTime: new Date(data[index].createTime),
          lastContent: data[index].content ? data[index].content : "新消息",
        };
      }
      return userList;
      // IMUI.initContacts(userList);
      // setTimeout(() => {
      //   IMUI.changeContact(userList[0].id);
      // }, 2000);
    },

    // 拉取消息列表
    // 现在刷新页面应该能够看到联系人了，但是点击联系人的话右边会一直处于加载中，这时需要监听 pull-messages 事件。
    handlePullMessages(contact, next, instance) {
      const otheruser = {
        id: contact.id,
        displayName: "在线客服",
        avatar: contact.avatar,
      };
      // console.log(otheruser, "otheruserotheruser");
      const hostoryList = [];
      getUserHostorylist({
        sendUser: this.sendUser,
        pageNum: this.pageNum,
        pageSize: 10,
      }).then((res) => {
        this.pageNum++;
        for (let index = 0; index < res.rows.length; index++) {
          hostoryList[index] = {
            id: res.rows[index].msgId,
            status: "succeed",
            type: res.rows[index].msgType,
            sendTime: new Date(res.rows[index].createTime),
            fileName: res.rows[index].content,
            fileSize: res.rows[index].remark,
            content:
              res.rows[index].msgType == "image" ||
              res.rows[index].msgType == "file"
                ? res.rows[index].picUrl
                : res.rows[index].content,
            toContactId: res.rows[index].toUser,
            fromUser:
              res.rows[index].sendUser == otheruser.id ? otheruser : this.user,
          };
        }
        hostoryList.reverse();
        if (res.total < 10) {
          next(hostoryList, true);
        } else {
          if (
            res.total == instance.getMessages(instance.currentContactId).length
          ) {
            next(hostoryList, true);
          } else {
            next(hostoryList, false);
          }
        }
      });
    },

    bootomMessage(data) {
      let that = this;
      this.$notify({
        title: "新消息",
        message: data.sendUserName + "：" + data.content,
        position: "bottom-right",
        duration: 4000,
        onClick() {
          that.showImin();
        },
      });
    },

    handleMenuAvatarClick() {
      console.log("Event:menu-avatar-click");
    },

    //选择文件后触发的事件
    zh_uploadFile_change(evfile, message, nextx) {
      console.log(evfile, message, nextx, "下载");
      const key = evfile.name || null;
      let _this = this; //下方用到的参数
      var config = {
        useCdnDomain: true,
        region: null,
      };
      var putExtra = {
        fname: "",
        params: {},
        mimeType: null,
      };
      // console.log(_this.qiniuData.token, "_this.qiniuData.token");
      // console.log(this.qiniuData, "asdasdsajdghjash");
      var observable = qiniu.upload(
        evfile,
        key,
        _this.qiniuData.token,
        putExtra,
        config
      );

      var observer = {
        next(res) {
          console.log(res.total.percent, "123");
        },
        error(err) {
          console.log(err, "456");
        },
        complete(res) {
          console.log(res, "789");
          console.log(_this.upload_qiniu_addr + res.key);
          message.content = _this.upload_qiniu_addr + res.key;
          _this.sendMessageforConent(message, nextx);
        },
      };
      var subscription = observable.subscribe(observer); // 上传开始
    },
  },
};
</script>

<style scoped>
.close_btn {
  position: absolute;
  top: 1%;
  right: 3%;
  z-index: 999;
  color: black;
  font-size: 19px;
}
.start_btn {
  position: fixed;
  /* top: 1%; */
  right: 2%;
  z-index: 999;
  color: black;
  font-size: 19px;
  bottom: 10%;
}
.item {
  margin-top: 10px;
  margin-right: 40px;
}
</style>