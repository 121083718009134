import request from '@/utils/request'

// 获取七牛token
export function getQnToken() {
  return request({
    url: '/file/out/qiniu/getUploadToken',
    method: 'get',
  })
}

//获取无用户token的七牛云token
export function getNoLoginQnToken() {
  return request({
    url: '/qiniu/getUploadToken',
    method: 'get',
  })
}
